import {PointsType} from "modules/utils/enums";

const JSON_URL = process.env.REACT_APP_JSON_URL || "";
const API_URL = process.env.REACT_APP_API_URL || "";
export const ENV = process.env.REACT_APP_ENVIRONMENT;
export const BASE_URL = process.env.REACT_APP_BASE_URL || "";
const STORAGE_VERSION = process.env.REACT_APP_STORAGE_VERSION || "";
export const SSO_URL = process.env.REACT_APP_SSO_URL || "";
export const SSO_URL_SCRIPT = process.env.REACT_APP_SSO_URL_SCRIPT || "";
const LOGIN_BUTTONS_URL = process.env.REACT_APP_LOGIN_BUTTONS_URL || "";
export const IS_LOCALHOST = process.env.REACT_APP_IS_LOCALHOST;
export const IS_OFF_SEASON = JSON.parse(process.env.REACT_APP_IS_OFF_SEASON || "false");
export const REACT_APP_LINK = process.env.REACT_APP_LINK || "";
export const FANTASY_REDIRECT_LINK = process.env.REACT_APP_FANTASY_REDIRECT_LINK || "";
export const SHARE_MESSAGE_GLOBAL = `Predict the next MotoGP™ pole position lap time! Try your luck! The closer you are to the time, the better your score and the more chance you have of winning a Tissot watch or MotoGP VIP Village™ passes`;
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const FLAGS_URL = IMAGES_URL + "flags/";
export const RIDERS_URL = IMAGES_URL + "riders/";
export const TRACKS_URL = IMAGES_URL + "tracks/";
export const SECRET = JSON.parse(process.env.REACT_APP_SECRET || "false");
export const PREDICTION_RESULTS = {
	[PointsType.Perfect]: {
		color: "#00AB30",
		key: "prediction.page.perfect_prediction",
		text: "perfect prediction!",
		share_key: "prediction.page.perfect_prediction_share",
		share_message:
			"Spot on, I guessed the perfect pole position time! I predicted the exact Minutes, Seconds and Thousands. do you want to try your luck?",
	},
	[PointsType.HalfASecond]: {
		color: "#F49C0A",
		key: "prediction.page.half_second_prediction",
		text: "within 250 thousands",
		share_key: "prediction.page.half_second_prediction_share",
		share_message:
			"How close was that! I scored 60 points in MotoGP™ Predictor I was less than a quarter of a second away from the MotoGP™ pole position lap time. Do you want to give it a try?",
	},
	[PointsType.WithinASecond]: {
		color: "#BCBCBC",
		key: "prediction.page.second_prediction",
		text: "within half of a second",
		share_key: "prediction.page.second_prediction_share",
		share_message:
			"How close was that! I scored 40 points in MotoGP™ Predictor. I was less than half a second away from the MotoGP™ pole position lap time. Do you want to give it a try?",
	},
	[PointsType.WithinTwoSeconds]: {
		color: "#B14403",
		key: "prediction.page.two_seconds_prediction",
		text: "within 1 second",
		share_key: "prediction.page.two_seconds_prediction_share",
		share_message:
			"How close was that! I scored 20 points in MotoGP™ Predictor. I was less than a second away from the MotoGP™ pole position lap time. Do you want to give it a try?",
	},
	[PointsType.BeyondTwoSeconds]: {
		color: "#FFFFFF",
		key: "prediction.page.beyond_two_seconds_prediction",
		text: "over 1 sec",
		share_key: "prediction.page.beyond_two_seconds_prediction_share",
		share_message:
			"Oh no! I didn't manage to guess the pole position lap time in MotoGP™ this week, let's hope I have better luck next time!",
	},
};

export const FANTASY_LINK = process.env.REACT_APP_FANTASY_LINK || "";

export const ACCOUNT_LINK = `${process.env.REACT_APP_DORNA_ACCOUNT_LINK || ""}#predictor`;

export abstract class Constant {
	public static get LOGIN_BUTTONS_URL() {
		return LOGIN_BUTTONS_URL;
	}

	public static get SSO_URL_SCRIPT() {
		return SSO_URL_SCRIPT;
	}

	public static get API_URL() {
		return API_URL;
	}

	public static get JSON_URL() {
		return JSON_URL;
	}
	public static get BASE_URL() {
		return BASE_URL;
	}

	public static get STORAGE_VERSION() {
		return STORAGE_VERSION;
	}
}
